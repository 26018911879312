import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'
import 'select2'
import 'select2/dist/css/select2.css'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
import {
  generatePresignedUrl,
  generatePresignedUrls,
  uploadFileInForm,
  uploadFilesToS3,
  areAllFilesUploaded,
  isFileUploaded,
} from '../../modules/s3_uploader'

export default class extends Controller {
  static targets = [
    'cartFormWrapper',
    'cartForm',
    'uploadOrder',
    'companySelection',
    'phoneInput',
    'validMsg',
    'errorMsg',
  ]

  static phoneErrorMap = [
    'The phone number is invalid, please re-enter',
    'Invalid country code',
    'The phone number is too short, please re-enter',
    'The phone number is too long, please re-enter',
    'The phone number is invalid number, please re-enter',
  ]

  connect() {
    this.bindHandleEvent()
  }

  bindHandleEvent() {
    this.cartFormTarget.addEventListener('submit', this.submitForm.bind(this))
    window.addEventListener('popstate', this._handlePopState.bind(this))

    new HSAddField('.js-add-field', {
      addedField: function (field) {
        field.classList.add('order-item')

        // bind event to new added field
        field
          .querySelector('.s3-upload-item')
          .addEventListener('change', (e) => {
            generatePresignedUrls('orders/', e.target)
          })

        field.querySelectorAll('input').forEach((input) => {
          input.setAttribute('required', true)
        })

        field.querySelectorAll('select').forEach((select) => {
          select.setAttribute('required', true)
        })
      },
    })

    if (this.hasUploadOrderTarget) {
      document.querySelectorAll('.btn-delete-order').forEach((btn) => {
        btn.addEventListener('click', this.handleDeleteOrder.bind(this))
      })
    }
  }

  handleDeleteOrder(event) {
    var orderItem = event.target.closest('.order-item')
    if (!orderItem) return

    orderItem.setAttribute('hidden', true)
    orderItem.setAttribute('deleted', true)
  }

  collectOrderData(form) {
    var orderData = []

    form.querySelectorAll('.order-item').forEach((item) => {
      const orderItem = {
        order_number: item.querySelector('.order-number').value,
        total_amount: item
          .querySelector('.total-amount')
          .value.replace(/,/g, ''),
        document_type: item.querySelector('.document-type').value,
      }

      const orderIdElement = item.querySelector('.order-id')
      if (orderIdElement) orderItem.order_id = orderIdElement.value

      const fileElement = item.querySelector('.s3-upload-item')
      if (fileElement) {
        const fileData = JSON.parse(fileElement.getAttribute('data-file'))
        orderItem.order_file = fileData.key
        orderItem.order_files = fileData.map((file) => file.key)
      }

      const deletedElement = item.getAttribute('deleted')
      if (deletedElement) orderItem.deleted = true

      orderData.push(orderItem)
    })

    const inputElement = document.createElement('input')
    inputElement.type = 'hidden'
    inputElement.name = 'order_data'
    inputElement.value = JSON.stringify(orderData)

    form.appendChild(inputElement)
    return orderData
  }

  isOrderOverLimit(form, orderData) {
    // let availableLimit = form.querySelector('.available-limit').value
    // if (availableLimit)
    //   availableLimit = parseFloat(availableLimit.replace(/\D/g, ''))
    // if (isNaN(availableLimit) || availableLimit === undefined) return false
    //
    // const totalAmount = orderData
    //   .filter((item) => !item.deleted)
    //   .reduce((acc, item) => {
    //     return acc + parseFloat(item.total_amount)
    //   }, 0)
    // return totalAmount > availableLimit
  }

  async submitForm(event) {
    const form = event.currentTarget
    if (form.elements['is_ready']) return

    event.preventDefault()

    var self = this

    // Specific for upload page
    if (this.hasUploadOrderTarget) {
      if (!areAllFilesUploaded(form)) {
        form.submit.disabled = true
        await this._uploadAllFiles(form, 'orders/')
        form.submit.disabled = false
        return form.submit.click()
      }

      const orderData = this.collectOrderData(form)

      form
        .querySelector('#cart-confirm-proceed')
        .addEventListener('click', () => {
          form.submit.disabled = false
          form.querySelector('#confirm-order').click()
          form.submit.click()
          return
        })

      // if (
      //   !form.querySelector('#confirm-order').checked &&
      //   this.isOrderOverLimit(form, orderData)
      // ) {
      //   // show modal
      //   form.querySelector('.confirm-cart-modal').click()
      //   form.submit.disabled = true
      //   return
      // }
    }

    var self = this
    // Submit the form data via AJAX
    fetch(form.action, {
      method: form.method,
      body: new FormData(form),
      headers: {
        'x-requested-with': 'XMLHttpRequest',
      },
    })
      .then((response) => response.text())
      .then((newContent) => {
        self.cartFormWrapperTarget.outerHTML = newContent

        // Set the new URL
        history.pushState({}, '', self.cartFormTarget.action)

        self.bindHandleEvent()
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  _resetValidations() {
    this.phoneInputTarget.classList.remove('field_with_errors')
    this.errorMsgTarget.innerHTML = ''
    this.errorMsgTarget.classList.add('hide')
    this.validMsgTarget.classList.add('hide')
  }

  _handlePopState() {
    window.location.reload()
  }

  _addHiddenInput(formElement, name, value) {
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = name
    hiddenInput.value = value
    formElement.appendChild(hiddenInput)
  }

  _uploadAllFiles(formElement, s3FilePrefix) {
    const fileElements = formElement.querySelectorAll('.s3-upload-item')

    // Create an array of promises for each file upload
    const uploadPromises = Array.from(fileElements).map((fileElement) => {
      if (fileElement.files.length === 0 || isFileUploaded(fileElement)) {
        return Promise.resolve() // Return resolved promise if no files or already uploaded
      }

      return new Promise((resolve, reject) => {
        uploadFilesToS3(
          s3FilePrefix,
          fileElement,
          (res) => {
            const keyJSON = JSON.stringify(res.map((r) => r.key))
            this._addHiddenInput(formElement, fileElement.name, keyJSON)
            resolve() // Resolve the promise after successfully uploading
          },
          (err) => reject(err), // Reject the promise if there's an error
        )
      })
    })

    // Wait for all file upload promises to complete
    return Promise.all(uploadPromises)
      .then(() => {
        console.log('All files have been uploaded.')
      })
      .catch((error) => {
        console.error('Error uploading files:', error)
      })
  }
}
