import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['']

  connect() {
    if (!document.querySelector('.js-select')?.tomselect) {
      HSCore.components.HSTomSelect.init('.js-select', {
        onChange: function (val) {
          window.location.href = val
        },
      })
    }
  }
}
