import { Controller } from '@hotwired/stimulus'
import {
  generatePresignedUrl,
  uploadFileInForm,
  areAllFilesUploaded,
} from '../../modules/s3_uploader'
export default class extends Controller {
  static targets = ['supplierForm', 'uploadSupplier']
  connect() {
    this.supplierFormTarget.addEventListener(
      'submit',
      this.submitForm.bind(this),
    )
    this.bindUploadFolder(document.querySelectorAll('input[type=file]'))
    this.setUpMultipleSupplierForm()
  }

  bindUploadFolder(fileInputs) {
    fileInputs.forEach((fileInput) => {
      fileInput.addEventListener('change', (e) => {
        generatePresignedUrl('supplier_documents/', e.target)
      })
    })
  }

  setUpMultipleSupplierForm() {
    const bindUploadFolderFunction = this.bindUploadFolder

    new HSAddField('.js-add-field', {
      addedField: function (field) {
        field.classList.add('supplier-item')
        bindUploadFolderFunction(field.querySelectorAll('input[type=file]'))
        field.querySelectorAll('input.requiredField').forEach((input) => {
          input.setAttribute('required', true)
        })
      },
    })

    if (this.hasUploadSupplierTarget) {
      this.uploadSupplierTarget
        .querySelectorAll('.btn-delete-supplier')
        .forEach((btn) => {
          btn.addEventListener('click', this.handleDeleteSupplier.bind(this))
        })
    }
  }

  handleDeleteSupplier(event) {
    var item = event.target.closest('.supplier-item')
    if (!item) return

    item.setAttribute('hidden', true)
    item.setAttribute('deleted', true)
  }

  submitForm(e) {
    const form = e.currentTarget
    if (form.elements['is_ready']) return

    e.preventDefault()

    if (this.hasDuplicateSupplierName(form)) {
      alert('Please remove duplication supplier name')
      return
    }

    if (!areAllFilesUploaded(form)) {
      uploadFileInForm(form, () => {
        this.collectSupplierData(form)
        form.submit()
      })
    } else {
      this.collectSupplierData(form)
      form.submit()
    }
  }

  collectSupplierData(form) {
    var supplierData = []

    form.querySelectorAll('.supplier-item').forEach((item) => {
      const supplierItem = {
        name: item.querySelector('.supplier-name').value,
        email: item.querySelector('.supplier-email')?.value,
        contract_file_key: this.getFileKey(
          item.querySelector('.supplier-contract-file'),
        ),
        supporting_file_key: this.getFileKey(
          item.querySelector('.supplier-supporting-file'),
        ),
      }
      const deletedElement = item.getAttribute('deleted')
      if (deletedElement) supplierItem.deleted = true
      supplierData.push(supplierItem)
    })

    const inputElement = document.createElement('input')
    inputElement.type = 'hidden'
    inputElement.name = 'supplier_data'
    inputElement.value = JSON.stringify(supplierData)

    form.appendChild(inputElement)
  }

  hasDuplicateSupplierName(form) {
    var supplierNames = new Set()

    for (const item of form.querySelectorAll('.supplier-item')) {
      const supplierName = item.querySelector('.supplier-name').value
      if (supplierNames.has(supplierName)) {
        return true
      }
      supplierNames.add(supplierName)
    }

    return false
  }

  getFileKey(fileElement) {
    if (fileElement) {
      const fileData = fileElement.getAttribute('data-file')
      return fileData ? JSON.parse(fileData).key : fileData
    }
  }

  extractSupplierCSV(event) {
    const file = event.target.files[0]
    if (!file) {
      return
    }
    const reader = new FileReader()
    reader.onload = (e) => {
      const content = e.target.result
      const rows = content.split('\n')
      const parsedData = rows
        .slice(1)
        .map((row) => row.split(',').map((cell) => cell.trim()))
      const supplierNames = parsedData.map((row) => row[0])
      supplierNames.forEach((name) => {
        this.addSupplierRow(name)
      })
      event.target.value = ''
    }
    reader.readAsText(file)
  }

  addSupplierRow(name) {
    if (!name) {
      return
    }
    const addField = this.uploadSupplierTarget.querySelector('.js-create-field')
    const container = document.getElementById(
      'addAnotherSupplierFieldContainer',
    )
    if (!container || !addField) {
      console.error('HS-Add-Field trigger not found.')
      return
    }

    const lastRow = container.lastElementChild
    if (lastRow) {
      let nameInput = lastRow.querySelector('.supplier-name')
      let emailInput = lastRow.querySelector('.supplier-email')
      let contractFileInput = lastRow.querySelector('.supplier-contract-file')
      let supportingFileInput = lastRow.querySelector(
        '.supplier-supporting-file',
      )
      if (
        !(
          nameInput.value ||
          contractFileInput.value ||
          supportingFileInput.value ||
          emailInput.value
        )
      ) {
        let deleteButton = lastRow.querySelector('.js-delete-field')
        deleteButton.click()
      }
    }

    addField.click() // Simulate clicking the button or trigger the field creation
    const newFieldRow = container.lastElementChild
    const nameInput = newFieldRow.querySelector('.supplier-name')
    if (nameInput) {
      nameInput.value = name // Set the supplier name from the CSV
    }
  }

  clickUploadCSV(event) {
    event.target.blur()
    const uploadInput = this.supplierFormTarget.querySelector('#csv-upload')
    if (uploadInput) {
      uploadInput.click()
    }
  }

  downloadSampleCsv(event) {
    event.preventDefault()

    // The base64-encoded CSV content passed from the server
    const encodedCsv = this.element.dataset.encodedCsv
    const binaryString = atob(encodedCsv)
    const blob = new Blob([binaryString], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'supplier_example.csv'
    link.click()
  }
}
